import { type Ref, type ShallowRef, onBeforeUnmount, ref, watch } from 'vue'

import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { useIntersectionObserver } from '@vueuse/core'

import type { UserCommentProps } from './UserCommentView.vue'

/**
 * Helper function that returns a function which calls trackReviewImpression with the right data
 */
export function useReviewImpressionTracker() {
  const {
    market: { countryCode: currentCountryCode },
  } = useMarketplace()

  const { trackReviewImpression } = useTracking()

  function trackExtendedReviewImpression({
    userComment,
    isTranslated,
  }: {
    userComment: UserCommentProps
    isTranslated: boolean
  }) {
    const isReviewFromCurrentCountry =
      userComment.countryCode === currentCountryCode

    trackReviewImpression({
      ...userComment.product,
      averageRate: userComment.averageRate,
      countryCode: userComment.countryCode,
      isReviewFromCurrentCountry,
      isReviewTranslated: isTranslated,
      ...(userComment.trackingData?.onImpression || {}),
    })
  }

  return {
    trackExtendedReviewImpression,
  }
}

/**
 * Calls trackReviewImpression que the review appears in the viewport
 */
export function useTrackReviewImpressionInViewport(
  target: ShallowRef<HTMLElement | null>,
  {
    userComment,
    isTranslated,
  }: {
    userComment: UserCommentProps
    isTranslated: Ref<boolean>
  },
) {
  const hasTriggeredImpression = ref(false)

  const { trackExtendedReviewImpression } = useReviewImpressionTracker()

  const { stop } = useIntersectionObserver(target, ([{ isIntersecting }]) => {
    if (isIntersecting) {
      trackExtendedReviewImpression({
        userComment,
        isTranslated: isTranslated.value,
      })

      hasTriggeredImpression.value = true
    }
  })

  watch(hasTriggeredImpression, () => {
    if (hasTriggeredImpression.value) {
      stop()
    }
  })

  onBeforeUnmount(() => {
    stop()
  })
}
