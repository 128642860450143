import { ref } from 'vue'

import { defineStore } from 'pinia'

type ScrollRegistry = {
  elements: Map<string, HTMLElement>
}

export const useReviewsScrollRegistry = defineStore(
  'reviewsScrollRegistry',
  () => {
    const registry = ref<ScrollRegistry>({
      elements: new Map<string, HTMLElement>(),
    })

    function register(id: string, element: HTMLElement) {
      registry.value.elements.set(id, element)
    }

    function unregister(id: string) {
      registry.value.elements.delete(id)
    }

    /**
     * Scrolls to the element with the given id
     * @param id - The id of the element to scroll to
     */
    function scrollTo(id: string) {
      if (import.meta.client) {
        const el = registry.value.elements.get(id)
        el?.scrollIntoView({ behavior: 'smooth' })
      }
    }

    return {
      registry,
      register,
      unregister,
      scrollTo,
    }
  },
)
